import axios from "axios";
import Settings from "./settings.service";
import Util from "../util";
let authToken = "";

const HTTP = axios.create({
  baseURL: Settings.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "original-source": "https://www.meemapps.com/",
  },
});
HTTP.defaults.headers.common.Accept = "*/*";

// Get the countyCode query param from the current URL
const getCountyCodeFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("countryCode");
};

HTTP.interceptors.request.use(
  (config) => {
    if (config.headers["needAutherization"]) {
      const user = Util.getCookie("user")
        ? JSON.parse(Util.getCookie("user"))
        : null;
      config.headers["authentication"] = authToken
        ? authToken
        : user
        ? user.token
        : null;

      delete config.headers["needAutherization"];
    }

    // Add countyCode to query params if it exists in the URL
    const countryCode = getCountyCodeFromURL();
    if (countryCode) {
      config.params = {
        ...(config.params || {}),
        countryCode,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

HTTP.set_session = (val) => {
  authToken = val;
};

export default HTTP;
